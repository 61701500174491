import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Storage } from '@capacitor/storage';
import { BehaviorSubject, from } from 'rxjs';
import { Auth, signInWithEmailAndPassword, createUserWithEmailAndPassword, 
  updateProfile, updateEmail, updatePassword } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { AlertController, LoadingController } from '@ionic/angular';
import { AccountPage } from '../pages/account/account.page';
const TOKEN_KEY = 'my_token';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  token = '';

  constructor(
    private http: HttpClient, 
    private auth: Auth,
    private router: Router,
    private alertController: AlertController,
    private loadingController: LoadingController
    ) { 
    this.loadToken();
  }

  async loadToken() {
    const token = await Storage.get({ key: TOKEN_KEY });    
    if (token && token.value) {
      console.log('set token: ', token.value);
      this.token = token.value;
      this.isAuthenticated.next(true);
    } else {
      this.isAuthenticated.next(false);
    }
  }

  signup(newuser) {
    return new Promise<any> ( (resolve, reject) =>{
      createUserWithEmailAndPassword(this.auth, newuser.email, newuser.password).then(
        res => {
          resolve(res);
          this.isAuthenticated.next(true);
          updateProfile(this.auth.currentUser, { displayName: newuser.firstname });
          return from(Storage.set({key: TOKEN_KEY, value: newuser.value}));
        },
        error => {
          reject(error);
          this.isAuthenticated.next(false);
        }
      )
    })
  }

  login(credentials) {
    return new Promise<any> ( (resolve, reject) =>{
      signInWithEmailAndPassword(this.auth, credentials.email, credentials.password).then(
        res => {
          resolve(res);
          this.isAuthenticated.next(true);
          return from(Storage.set({key: TOKEN_KEY, value: credentials.value}));
        },
        error => {
          reject(error);
          this.isAuthenticated.next(false);
        }
      )
    })
  }

  logout(): Promise<void> {
    this.isAuthenticated.next(false);
    return Storage.remove({key: TOKEN_KEY});
  }


  async updateName(newName) {
    const loading = await this.loadingController.create();
    await loading.present();
    const alertNameYes = await this.alertController.create({
      message: 'Account Display Name Updated!',
      buttons: ['OK'],
    });
    const alertNameNo = await this.alertController.create({
      message: 'Could not update display name',
      buttons: ['Try Again'],
    });

    try{
      updateProfile(this.auth.currentUser, {displayName: newName}).then(
        async (res) => {
          await loading.dismiss();
          this.auth.currentUser.reload();
          await alertNameYes.present();
          // this.router.navigateByUrl('/');
          this.router.navigateByUrl('/account')
            .then(() => {
              window.location.reload();
            });
        },
        async (res) => {
          await loading.dismiss();
          await alertNameNo.present();
        }
    )}
    catch(err) {
      console.log(err);
    }
  }

  async updateEmail(newEmail) {
    const loading = await this.loadingController.create();
    await loading.present();
    const alertEmailYes = await this.alertController.create({
      message: 'Account Email Updated!',
      buttons: ['OK'],
    });
    const alertEmailNo = await this.alertController.create({
      message: 'Recent login required to update email address. Please log out & try again',
      buttons: ['Try Again'],
    });

    try{
      updateEmail(this.auth.currentUser, newEmail).then(
        async (res) => {
          await loading.dismiss();
          this.auth.currentUser.reload();
          await alertEmailYes.present();
          this.router.navigateByUrl('/account')
            .then(() => {
              window.location.reload();
            });
        },
        async (res) => {
          await loading.dismiss();
          await alertEmailNo.present();
          console.log(res);
        }
    )}
    catch(err) {
      console.log(err);
    }
  }


  async updatePassword(newPassword) {
    const loading = await this.loadingController.create();
    await loading.present();
    const alertPwordYes = await this.alertController.create({
      message: 'Account Password Updated!',
      buttons: ['OK'],
    });
    const alertPwordNo = await this.alertController.create({
      message: 'Recent login required to update password. Please log out & try again',
      buttons: ['Try Again'],
    });

    try{
      updatePassword(this.auth.currentUser, newPassword).then(
        async (res) => {
          await loading.dismiss();
          this.auth.currentUser.reload();
          await alertPwordYes.present();
          this.router.navigateByUrl('/account')
            .then(() => {
              window.location.reload();
            });
        },
        async (res) => {
          await loading.dismiss();
          await alertPwordNo.present();
          console.log(res);
        }
    )}
    catch(err) {
      console.log(err);
    }
  }

}
