import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { AuthenticationService } from './services/authentication.service';
import { Auth } from '@angular/fire/auth';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  
  pages = [
    {
      title: 'Home', 
      url: '/home',
      icon: 'home'
    },
    // {
    //   title: 'Account', 
    //   url: '/account',
    //   icon: 'person'
    // },
    {
      title: 'Donate', 
      url: '/tab1',
      icon: 'wallet'
    },
    {
      title: 'Volunteer', 
      url: '/tab2',
      icon: 'heart'
    },
    // {
    //   title: 'Buy & Donate', 
    //   url: '/tab3',
    //   icon: 'cart'
    // },
  ]

  constructor(
    private authService: AuthenticationService, 
    private router: Router,
    private alertController: AlertController,
    private auth: Auth
  ) {}

  async logout() {

    const alertYes = await this.alertController.create({
      message: 'Are you sure you want to logout of Meliora?',
      buttons: [
        {
          text: 'Yes',
          handler: () => {
            this.authService.logout();
            this.router.navigateByUrl('/login', { replaceUrl: true });
            console.log('Meant to logout')
          }
        }, 
        {
          text: 'No',
          handler: () => {
            console.log('Did not mean to logout')
          }
        }],
    });

    const alertNo = await this.alertController.create({
      message: 'No account logged in',
      buttons: [
        {
          text: 'Log in',
          handler: () => {  this.router.navigateByUrl('/login', { replaceUrl: true }); }
        },
        {
          text: 'Cancel',
        }]
    });

    if (this.authService.isAuthenticated.value){
      await alertYes.present();
    }
    else{
      await alertNo.present();
    } 
  }

  async account(){
    const alert = await this.alertController.create({
      message: 'No account logged in',
      buttons: [
        {
          text: 'Log in',
          handler: () => {  this.router.navigateByUrl('/login', { replaceUrl: true }); }
        },
        {
          text: 'Cancel',
        }]
    });

    if (this.authService.isAuthenticated.value){
      this.router.navigateByUrl('/account', { replaceUrl: true });
    }
    else{
      await alert.present();
    } 
  }

}