import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';

export const intro_key = 'intro_seen';
import { Storage } from '@capacitor/storage';

@Injectable({
  providedIn: 'root'
})
export class IntroGuard implements CanLoad {
  
  constructor(private router: Router) {}

  async canLoad(): Promise<boolean> {
    const hasSeenIntro = await Storage.get({ key: intro_key });

    if (hasSeenIntro && (hasSeenIntro.value == 'true')){
      return true;
    }
    else {
      this.router.navigateByUrl('/intro', { replaceUrl: true });
      return true;
    }
  }
}
