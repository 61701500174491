import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { Observable, filter, take, map } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AutoLoginGuard implements CanLoad {
  constructor(private authService: AuthenticationService, private router: Router) {}

  canLoad(): Observable<boolean> {
    return this.authService.isAuthenticated.pipe(
      filter(val => val !== null), //filter out initial behavior subject value
      take(1), // take just true or false, not null. Otherwise the observable doesnt complete
      map(isAuthenticated => {
        if (isAuthenticated) {
          // Directly open the inside area
          this.router.navigateByUrl('/home', { replaceUrl: true });
        }
        else {
          // Allow user to login
          return true;
        }
      })
    );
  }
}
