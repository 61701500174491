import { NgModule } from '@angular/core';
import { PreloadAllModules, PreloadingStrategy, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { AutoLoginGuard } from './guards/auto-login.guard';
import { IntroGuard } from './guards/intro.guard';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule),
    // canLoad: [IntroGuard, AutoLoginGuard]
    canLoad: [AutoLoginGuard]
  },
  {
    path: '',
    // redirectTo: '/login',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule),
    // canLoad: [AuthGuard]
    canLoad: [IntroGuard]
  },
  {
    path: 'intro',
    loadChildren: () => import('./pages/intro/intro.module').then( m => m.IntroPageModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./pages/signup/signup.module').then( m => m.SignupPageModule)
  },
  {
    path: 'account',
    loadChildren: () => import('./pages/account/account.module').then( m => m.AccountPageModule),
    // new
    // canLoad: [AuthGuard]
  },
  {
    path: 'tab1',
    loadChildren: () => import('./donate/tab1/tab1.module').then(m => m.Tab1PageModule)
  },
  {
    path: 'tab2',
    loadChildren: () => import('./volunteer/tab2/tab2.module').then(m => m.Tab2PageModule)
  },
  {
    path: 'tab3',
    loadChildren: () => import('./buyndonate/tab3/tab3.module').then(m => m.Tab3PageModule)
  },
  {
    path: 'tab11',
    loadChildren: () => import('./donate/tab11/tab11.module').then( m => m.Tab11PageModule),
    // new
    canLoad: [AuthGuard]
  },
  {
    path: 'tab12',
    loadChildren: () => import('./donate/tab12/tab12.module').then( m => m.Tab12PageModule)
  },
  {
    path: 'tab21',
    loadChildren: () => import('./volunteer/tab21/tab21.module').then( m => m.Tab21PageModule),
    // new
    canLoad: [AuthGuard]
  },
  {
    path: 'tab22',
    loadChildren: () => import('./volunteer/tab22/tab22.module').then( m => m.Tab22PageModule)
  },
  {
    path: 'tab23',
    loadChildren: () => import('./volunteer/tab23/tab23.module').then( m => m.Tab23PageModule)
  },
  {
    path: 'tab24',
    loadChildren: () => import('./volunteer/tab24/tab24.module').then( m => m.Tab24PageModule)
  },
  {
    path: 'tab25',
    loadChildren: () => import('./volunteer/tab25/tab25.module').then( m => m.Tab25PageModule)
  },
  {
    path: 'tab31',
    loadChildren: () => import('./buyndonate/tab31/tab31.module').then( m => m.Tab31PageModule),
    // new
    canLoad: [AuthGuard]
  },
  {
    path: 'tab32',
    loadChildren: () => import('./buyndonate/tab32/tab32.module').then( m => m.Tab32PageModule)
  },
  {
    path: 'tab33',
    loadChildren: () => import('./buyndonate/tab33/tab33.module').then( m => m.Tab33PageModule)
  },
  {
    path: 'tab34',
    loadChildren: () => import('./buyndonate/tab34/tab34.module').then( m => m.Tab34PageModule)
  },
  {
    path: 'cart-modal',
    loadChildren: () => import('./thrift/cart-modal/cart-modal.module').then( m => m.CartModalPageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
    //RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}