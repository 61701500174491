import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { filter, map, Observable, take } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanLoad {

  constructor(private authService: AuthenticationService, private router: Router) {}

  canLoad(): Observable<boolean> {
    return this.authService.isAuthenticated.pipe(
      filter(val => val != null),
      take(1),
      map(isAuthenticated => {
        console.log('GUARD: ', isAuthenticated);

        if (isAuthenticated) {
          return true;
        }
        else {
          this.router.navigateByUrl('/login')
          return false;
        }
      })
    )
  }
}
